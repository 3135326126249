import {
  MYPLAN_ADD_DATA,
  MYPLAN_UPDATE_DATA,
  MYPLAN_GET_DATA,
  MYPLAN_DELETE_DATA,
  MYPLANMGRNOTE_ADD_DATA,
  MYPLANNOTE_ADD_DATA,
  MYPLANNOTE_DELETE_DATA,
  TEAMPLAN_GET_DATA,
  PLAN_BY_LOC_LEVEL_GET_DATA,
  MYLOC_GET_DATA,
  MYPLAN_EMP_LOC_PER_GET_DATA,
  PLAN_BY_EMP_GET_DATA,
  PRIORITY_BY_ID_GET_DATA,
  INSERT_T0_NEWQUARTER_ADD_DATA
} from "./Constants";

const initialState = {
  loading: false,
  error: null,
  myplans: [],
  myplan: {},
  newnote: false,
  teamplans: [],
  myloc:[],
  isSave:false,
  getLoad:false,
  allplans:[],
  mypriority:{}
};

const Reducer = (state = initialState, action) => {
  console.log("isSave ation", action)
  switch (action.type) {
    // case MYPLAN_DATA.MYPLAN_DATA_LOAD:
    //   return {
    //     ...state,
    //     loading: true,
    //     error: null,
    //     myplans: [],
    //   };
    // case MYPLAN_DATA.MYPLAN_DATA_SUCCESS:
    //   return {
    //     ...state,
    //     loading: false,
    //     myplans: action.myplans,
    //     error: null,
    //   };
    // case MYPLAN_DATA.MYPLAN_DATA_FAIL:
    //   return {
    //     ...state,
    //     loading: false,
    //     error: action.error,
    //     myplans: [],
    //   };
    //Add
    case MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        isSave: false,
        getLoad:false,
        error: null,
      };
    case MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        isSave: true,
        error: null,
      };
    case MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        isSave: false,
        error: action.error,
      };
       // Add manager note 
    case MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        //myplan: {},
        newnote : true,
        isSave: false,
        error: null,
      };
    case MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        //myplan: action.myplan,
        newnote : false,
        isSave: true,
        error: null,
      };
    case MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        //myplan: {},
        newnote : false,
        isSave: false,
        error: action.error,
      };

      // Add new Quarter
    case INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        isSave: false,
        getLoad:false,
        error: null,
      };
    case INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        isSave: true,
        error: null,
      };
    case INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        isSave: false,
        error: action.error,
      };

    // Add note 
    case MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        newnote : true,
        isSave: false,
        error: null,
      };
    case MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        newnote : false,
        isSave: true,
        error: null,
      };
    case MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        newnote : false,
        isSave: false,
        error: action.error,
      };
    // Delete Note
    case MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        newnote : true,
        isSave: false,
        error: null,
      };
    case MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        newnote : false,
        isSave: true,
        error: null,
      };
    case MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        newnote : false,
        isSave: false,
        error: action.error,
      }; 
    //Get
    case MYPLAN_GET_DATA.MYPLAN_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        getLoad:false,
        error: null,
      };
    case MYPLAN_GET_DATA.MYPLAN_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        getLoad:true,
        error: null,
      };
    case MYPLAN_GET_DATA.MYPLAN_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        getLoad:false,
        error: action.error,
      };
    //Get Priority by Id
    case PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        mypriority: {},
        error: null,
      };
    case PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        mypriority: action.myplan,
        error: null,
      };
    case PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        mypriority: {},
        error: action.error,
      };
    
      
    //Get My Location
    case MYLOC_GET_DATA.MYLOC_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myloc: {},
        getLoad:false,
        error: null,
      };
    case MYLOC_GET_DATA.MYLOC_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myloc: action.myplan,
        getLoad:false,
        error: null,
      };
    case MYLOC_GET_DATA.MYLOC_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myloc: {},
        getLoad:false,
        error: action.error,
      };
    //Team Get
    case TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        teamplans: {},
        error: null,
      };
    case TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        teamplans: action.myplan,
        error: null,
      };
    case TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        teamplans: {},
        error: action.error,
      };

    //All Plans by Location
    case PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        teamplans: {},
        error: null,
      };
    case PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        teamplans: action.myplan,
        error: null,
      };
    case PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        teamplans: {},
        error: action.error,
      };
    //Update
    case MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        error: null,
      };
    case MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        error: false,
      };
    case MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        error: action.error,
      };
    //Delete
    case MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        error: null,
      };
    case MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        error: null,
      };
    case MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        error: action.error,
      };
  
    //To Get individual team priority by emp, loc and period
    case MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        myplan: {},
        getLoad:false,
        isSave:false,
        error: null,
      };
    case MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        myplan: action.myplan,
        getLoad:false,
        isSave:false,
        error: null,
      };
    case MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        myplan: {},
        getLoad:false,
        isSave:false,
        error: action.error,
      };

    //To Get all plan by employee id
    case PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_LOAD:
      return {
        ...state,
        loading: true,
        allplans: {},
        error: null,
      };
    case PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_SUCCESS:
      return {
        ...state,
        loading: false,
        allplans: action.myplan,
        error: null,
      };
    case PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_FAIL:
      return {
        ...state,
        loading: false,
        allplans: {},
        error: action.error,
      };

    default:
      return {
        ...state,
      };
  }
};

export default Reducer;
