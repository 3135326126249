import React, { useEffect } from 'react';
import jsPDF from 'jspdf';
import DOMPurify from 'dompurify';
import { useDispatch, useSelector } from "react-redux";
import { loadPlanByEmpLocPerGetDataRequest } from "../redux/Actions";

const isEmptyObject = (obj) => {
    return obj && Object.keys(obj).length === 0 && obj.constructor === Object;
};

const GeneratePDF = ({ plans = [], period, year, view, isDownload }) => {
    const dispatch = useDispatch();
    const { myplan } = useSelector((state) => state.myPlans);
    console.log("My Plans", plans);

    useEffect(() => {
        const request = {
            "employee_id": plans['employee_id'],
            "location_id": plans['loc_id'],
            "period": period,
            "year": year
        };
        dispatch(loadPlanByEmpLocPerGetDataRequest(request));
    }, [period, year, plans['loc_id'], dispatch]);

    const addTextWithOverflowCheck = (doc, label, text, yPos, margin, contentHeight) => {
        const columnWidth = 60; // Width for the heading column
        const textWidth = 130; // Width for the content column
        const lineHeight = 5; // Height of each text line
        const boxPadding = 4; // Padding inside the box
        const headingTopPadding = 5; // Padding for the heading from the top of the box
        console.log("Label", text);
    
        // Set font for the label
        doc.setFont('helvetica', 'bold');
        doc.setTextColor(0, 0, 0);
        const labelLines = doc.splitTextToSize(label, columnWidth);
        
        // Set font for the text content
        doc.setFont('helvetica', 'normal');
        const textLines = doc.splitTextToSize(text, textWidth);
        
        // Calculate box heights
        const labelHeight = labelLines.length * lineHeight;
        const textHeight = textLines.length * lineHeight;
        const boxHeight = Math.max(labelHeight, textHeight) + 2 * boxPadding; // Ensure box fits content
    
        // Check for overflow and add new page if necessary
        if (yPos + boxHeight > contentHeight) {
            doc.addPage();
            yPos = margin; // Reset yPos after adding a new page
        }
    
        // Draw the box with a border and fill color
        doc.setDrawColor(169, 169, 169); // Grey color for border
        doc.setFillColor(255, 255, 255); // Light grey color for background
        doc.rect(columnWidth, yPos, textWidth + 8, boxHeight, 'FD'); // Draw box
    
        // Add heading text outside the box
        let headingY = yPos + headingTopPadding; // Adjusted position for heading
        labelLines.forEach(line => {
            doc.text(line, margin, headingY); // Place heading outside of the box
            headingY += lineHeight;
        });
    
        // Add content inside the box
        let contentY = yPos + boxPadding; // Adjusted position for content
        textLines.forEach(line => {
            doc.text(line, columnWidth + boxPadding, contentY + 3);
            contentY += lineHeight;
        });
        
        // Return updated yPos for the next block 
        return yPos + boxHeight + 5; // Additional space after the box
    };

    const processImageNote = (noteContent, doc, yPos, margin, contentHeight) => {
        console.log("Processing note with image and text...", noteContent);
        margin = 60;
        const imgRegex = /<img\b[^>]*src="([^"]+)"[^>]*>/gi;
        let match;
        let lastIndex = 0;
    
        const boxWidth = 138; // Width of the box containing image and text
        const boxPadding = 5; // Padding inside the box
        const imgWidth = 30; // Image width
        const imgHeight = 30; // Image height
        const lineHeight = 6; // Adjusted line height for text
    
        const textContentArray = [];
    
        // Extract text and image segments
        while ((match = imgRegex.exec(noteContent)) !== null) {
            const imageSrc = match[1];
            const textContent = noteContent.slice(lastIndex, match.index).replace(/<\/?[^>]+(>|$)/g, ''); // Strip HTML tags
            if (textContent) textContentArray.push({ type: "text", content: textContent });
            textContentArray.push({ type: "image", content: imageSrc });
            lastIndex = imgRegex.lastIndex;
        }
    
        // Add remaining text after the last image
        const remainingText = noteContent.slice(lastIndex).replace(/<\/?[^>]+(>|$)/g, '');
        if (remainingText) {
            textContentArray.push({ type: "text", content: remainingText });
        }
    
        // Calculate box height dynamically
        let totalHeight = 0;
        textContentArray.forEach(item => {
            if (item.type === "text") {
                const textLines = doc.splitTextToSize(item.content, boxWidth - imgWidth - 10);
                totalHeight += textLines.length * lineHeight;
            } else if (item.type === "image") {
                totalHeight += imgHeight + boxPadding;
            }
        });
        totalHeight += 1 * boxPadding; // Add padding to total height
    
        // Check for page overflow
        if (yPos + totalHeight > contentHeight) {
            doc.addPage();
            yPos = margin; // Reset yPos for the new page
        }
    
        // Draw the box
        doc.setDrawColor(169, 169, 169); // Grey border color
        doc.setFillColor(255, 255, 255); // White background
        doc.rect(margin, yPos, boxWidth, totalHeight - 5, 'FD'); // Draw box
    
        // Add text and images
        let currentYPos = yPos + boxPadding; // Start inside the box
        textContentArray.forEach(item => {
            if (item.type === "text") {
                const textLines = doc.splitTextToSize(item.content, boxWidth - imgWidth - 10);
                textLines.forEach(line => {
                    if (currentYPos + lineHeight > contentHeight) {
                        doc.addPage();
                        currentYPos = margin + boxPadding; // Reset position on new page
                    }
                    doc.text(line, margin + boxPadding , currentYPos); // Place text beside image
                    currentYPos += lineHeight;
                });
            } else if (item.type === "image") {
                if (currentYPos + imgHeight > contentHeight) {
                    doc.addPage();
                    currentYPos = margin + boxPadding; // Reset position on new page
                }
                doc.addImage(item.content, 'JPEG', margin + boxPadding, currentYPos, imgWidth, imgHeight);
                currentYPos += imgHeight + boxPadding; // Update position after image
            }
        });
    
        return currentYPos + 10; // Return updated yPos for the next content
    };
    
    
    

    
    const generatePDF = () => {
        const doc = new jsPDF();
        const margin = 11;
        const pageHeight = doc.internal.pageSize.height;
        const contentHeight = pageHeight - 2 * margin;
        let yPos = 5;

        plans.forEach((plan, index) => {
            if (index > 0) {
                doc.addPage();
                yPos = margin;
            }

            doc.setFillColor(16, 117, 100); // Green color
            doc.rect(margin, yPos, 190, 12, 'F'); // Manager
            doc.rect(margin, yPos + 10, 190, 12, 'F'); // Location
            doc.rect(margin, yPos + 20, 190, 7, 'F'); // Period
            doc.setTextColor(255, 255, 255);

            doc.setFontSize(14);
            doc.text(`Manager: ${plan.full_name}`, margin + 2, yPos + 10);
            doc.text(`Location: ${plan.location_name || ''}`, margin + 112, yPos + 10);
            doc.text(`Period: ${plan.period_name || `${plan.period}_${plan.year}`}`, margin + 2, yPos + 20);
            doc.text(`Last Updated On: ${plan.last_updated || ''}`, margin + 112, yPos + 20);

            yPos += 30;
            doc.setTextColor(0, 0, 0);
            doc.setFontSize(11);
            let i = 1;
            if (!isEmptyObject(myplan) && myplan.priorities && typeof myplan.priorities === 'object' && !isEmptyObject(myplan.priorities)) {
                Object.entries(myplan.priorities).forEach(([key, priority]) => {
                    // Add heading for each business priority
                    doc.setFillColor(200, 200, 200); // Light grey color for heading background
                    doc.rect(margin, yPos, 190, 8, 'F');
                    doc.setFont('helvetica', 'bold');
                    
                    doc.setFontSize(12);
                    
                    doc.text(`Business Priority ${i}  -  ${priority.business_priority}`, margin + 1, yPos + 6);
                    
                    yPos += 10;

                    yPos = addTextWithOverflowCheck(doc, 'Business Priority', priority.business_priority, yPos, margin + 1, contentHeight);
                    yPos = addTextWithOverflowCheck(doc, 'KPI to measure', priority.KPI_to_measure, yPos, margin+ 1, contentHeight);
                    yPos = addTextWithOverflowCheck(doc, 'Current State', priority.current_state, yPos, margin + 1, contentHeight);
                    yPos = addTextWithOverflowCheck(doc, 'My Actions', priority.my_actions, yPos, margin + 1, contentHeight);
                    yPos = addTextWithOverflowCheck(doc, 'Team Actions', priority.team_actions, yPos, margin + 1, contentHeight);

                    // Combine manager notes into a single box
                    
                        if (!isEmptyObject(priority.notes)) {
                            console.log("Priority Notes", priority.notes);
                            doc.setFont('helvetica', 'bold');
                            doc.setFontSize(11);
                            doc.text('Notes', margin, yPos + 5);
                            doc.setFont('helvetica', 'normal');
                            let notesContent ="";
                            Object.entries(priority.notes).forEach(([noteKey, note]) => {
                                const noteTitle = Object.keys(note)[0];
                                const noteContent = DOMPurify.sanitize(note[noteTitle]); // Sanitize the content
                                
                                notesContent += `${note['emp_name']} - ${noteTitle}:${noteContent}\n`;
                                
                            });
                            yPos = processImageNote(notesContent, doc, yPos, margin, contentHeight);

                        }                                                  
                    
 
                    doc.setDrawColor(255, 127, 14); // Green color
                    doc.line(margin, yPos, 200, yPos); // Horizontal line
                    yPos += 10;
                    i++;
                });
            } else {
                doc.setFontSize(12);
                doc.text('No priorities added', margin, yPos);
                yPos += 10;
            }

            if (index === plans.length - 1) {
                console.log("Testing",period,year);
                const fileName = `${plan.full_name}_${view === 'download' ? `${plan.period_name || `${plan.period}_${plan.year}`}`:`${period}_${year}`}.pdf`;
                console.log('Saving PDF as:', fileName);
                doc.save(fileName);
                isDownload = false;
            }
        });
        isDownload = false;
    };

    useEffect(() => {
        if (isDownload && myplan && !isEmptyObject(myplan)) {
            generatePDF();
        }
    }, [plans, isDownload, myplan]);

    return null;
};

export default GeneratePDF;
