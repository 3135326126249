import { takeLatest, put, call } from "redux-saga/effects";
import {
  DENSITY_DATA,
  DENSITY_GET_API,
  DENSITYCLASS_DATA,
  DENSITYCLASS_GET_API,
  DENSITY_ADD_DATA,
  DENSITY_POST_API,
  DENSITY_GET_DATA,
  DENSITY_GET_DETAIL_API,
  DENSITY_UPDATE_DATA,
  DENSITY_PUT_API,
  DENSITY_DELETE_DATA,
  DENSITY_DELETE_API,
} from "./Constants";
import {
  setDensityDataSuccess,
  setDensityDataFail,
  setDensityClassDataSuccess,
  setDensityClassDataFail,
  setDensityAddDataSuccess,
  setDensityAddDataFail,
  setDensityGetDataSuccess,
  setDensityGetDataFail,
  setDensityUpdateDataSuccess,
  setDensityUpdateDataFail,
  setDensityDeleteDataSuccess,
  setDensityDeleteDataFail
} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

function* getDensitiesDataService() {
  try {
    const densityResponse = yield call(getRequest, DENSITY_GET_API);
    const status = densityResponse.status;
    if (status === 200) {
      const data = densityResponse.data;
      yield put(setDensityDataSuccess(data));
    } else {
      yield put(setDensityDataFail(densityResponse.response.data));
    }
  } catch (error) {
    yield put(setDensityDataFail(error));
  }
}

function* getDensityClassDataService() {
  try {
    const densityClassResponse = yield call(getRequest, DENSITYCLASS_GET_API);
    const status = densityClassResponse.status;
    if (status === 200) {
      const data = densityClassResponse.data;
      yield put(setDensityClassDataSuccess(data));
    } else {
      yield put(setDensityClassDataFail(densityClassResponse.response.data));
    }
  } catch (error) {
    yield put(setDensityClassDataFail(error));
  }
}

function* postDensityDataService({ request }) {
  try {
    const response = yield call(
      postRequest,
      DENSITY_POST_API,
      request
    );
    const status = response.status;
    if (status === 200) {
      const newData = response.data;
      yield put(addSuccessAlert("Density class created"))
      yield put(setDensityAddDataSuccess(newData));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityAddDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("Could not create density class"))
    yield put(setDensityAddDataFail(error));
  }
}

function* getDensityDataService({ id }) {
  try {
    const getDensityDataResponse = yield call(
      getDetailRequest,
      DENSITY_GET_DETAIL_API + id
    );
    const status = getDensityDataResponse.status;
    if (status === 200) {
      const newData = getDensityDataResponse.data;
      yield put(setDensityGetDataSuccess(newData));
    } else {
      yield put(setDensityGetDataFail(getDensityDataResponse.response.data));
    }
  } catch (error) {
    yield put(setDensityGetDataFail(error));
  }
}

function* putDensityDataService({ request }) {
  try {
    const response = yield call(putRequest, DENSITY_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Density class updated"))
      yield put(setDensityUpdateDataSuccess(response.data));
    } else {
      // yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityUpdateDataFail(response.response.data));
    }
  } catch (error) {
    // yield put(addErrorAlert("could not update density class"))
    yield put(setDensityUpdateDataFail(error));
  }
}

function* deleteDensityDataService({ request }) {
  try {
    const response = yield call(deleteRequest, DENSITY_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("Density class deleted"))
      yield put(setDensityDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setDensityDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("unable to delete density class"))
    yield put(setDensityDeleteDataFail(error));
  }
}

export function* watchDensity() {
  yield takeLatest(DENSITY_DATA.DENSITY_DATA_LOAD, getDensitiesDataService);
  yield takeLatest(DENSITYCLASS_DATA.DENSITYCLASS_DATA_LOAD, getDensityClassDataService);
  yield takeLatest(
    DENSITY_ADD_DATA.DENSITY_ADD_DATA_LOAD,
    postDensityDataService
  );
  yield takeLatest(
    DENSITY_GET_DATA.DENSITY_GET_DATA_LOAD,
    getDensityDataService
  );
  yield takeLatest(
    DENSITY_UPDATE_DATA.DENSITY_UPDATE_DATA_LOAD,
    putDensityDataService
  );
  yield takeLatest(
    DENSITY_DELETE_DATA.DENSITY_DELETE_DATA_LOAD,
    deleteDensityDataService
  );
}
