import { useEffect, useState , useRef } from "react";
import { Card, Col, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import ListView from "../../../shared/components/listview/ListView";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadAuditDataRequest, loadAuditTablesRequest, loadAuditDataBySearchRequest } from "../redux/Actions";

const columns = [
    {
        display_name: "id",
        field: "id",
        type: "number",
        hide: true,
        sort: 'desc'
    },
    {
        display_name: "User",
        field: "created_by",
        type: "text",
    },
    {
        display_name: "Date",
        field: "created_on",
        type: "date",
    },
    {
        display_name: "Type",
        field: "action_type",
        type: "text",
    },
    {
        display_name: "Reference ID",
        field: 'reference_value',
        type: 'text',
    },
    {
        display_name: "JSON",
        field: "change_log",
        type: "jsonLog",
    },
]
export default function Audits() {
    const dispatch = useDispatch();
    let [showFilter, setShowFilter] = useState(false);
    let [selectedTableColumn, setSelectedTableColumn] = useState([]);
    let [operator, setOperator] = useState(["=", "like"])
    const searchInputRef = useRef(null);
    let [serachPlaceHolder, setSerachPlaceHolder] = useState("Search Content");

    let [selectedTable, setSelectedTable] = useState("");
    let [selectedColumn, setSelectedColumn] = useState("");
    let [selectedOperator, setSelectedOperator] = useState("=");
    
    useEffect(() => {
        // dispatch(loadAuditDataRequest("leave_type"))
        dispatch(loadAuditTablesRequest());
    }, [dispatch])
    let { audits, tables } = useSelector(state => state.audits)
    const formatColumnNames = (str) => {
        return str
          .split('_')
          .map(word => word.charAt(0).toUpperCase() + word.slice(1))
          .join(' ');
      };
    const changeSelect = (event) => {
        if (event.target.value) {
            const selectedOption = event.target.options[event.target.selectedIndex];
            const extraData = JSON.parse(selectedOption.getAttribute('data-extra')); // Parse the data back to an object
            setSelectedTableColumn(extraData)
            setSelectedTable(event.target.value)
            setShowFilter(true)
            dispatch(loadAuditDataRequest(event.target.value))
        }
    }
    const changeOperator = (e) => {
        setSelectedOperator(e.target.value); 
        if(e.target.value == 'like') {
            setSerachPlaceHolder('%Search Content%');
        } else {
            setSerachPlaceHolder('Search Content');
        }
    }
    

    const handleClick = () => {
        const inputValue = searchInputRef.current.value;
        let request = {
            "table": selectedTable,
            "column": selectedColumn,
            "operator": selectedOperator,
            'value': inputValue
        }
        dispatch(loadAuditDataBySearchRequest(request))
    };
    

    let [json, setJson] = useState({})
    let [showDetailsModal, setShowDetailsModal] = useState(false)
    let [detailsTitle, setDetailsTitle] = useState('')
    const onClick = (row) => {
        let byVal;
        let onVal;
        if (row.data.action_type == "create") {
            byVal = "Created By : "
            onVal = "Created On : "
        } else if (row.data.action_type == "update") {
            byVal = "Updated By : "
            onVal = "Updated On : "
        } else {
            byVal = "Deleted By : "
            onVal = "Deleted On : "
        }
        setJson(createDifferencesObj(row.data.previous_json, JSON.parse(row.data.change_log)))
        setShowDetailsModal(true);
        setDetailsTitle(byVal + row.data.created_by + ", " + onVal + new Date(row.data.created_on).toLocaleString());
    }
    return (
        <>
            <Row>
                <Col xl={4} xs={12}>
                    <Breadcrumbs
                        children={[
                            { label: "Audit" }
                        ]}
                    />
                </Col>
            </Row>
            <Row>
                <Card>
                    <Card.Body>
                        <Row>
                            <Col sm="auto">
                                <Form.Select onChange={changeSelect}>
                                    <option value="">Select Table</option>
                                    {tables.map((table) => {
                                        let string = String(table.source_table).split('_')
                                        let title = [];
                                        for (let word of string) {
                                            title.push(word.charAt(0).toUpperCase() + word.slice(1))
                                        }
                                        return <option key={table.source_table} value={table.source_table} data-extra={JSON.stringify(table.columns)} >{title.join(' ')}</option>
                                    })}
                                </Form.Select>
                            </Col>
                            {/* include column list in the dropdown 
                            add operator 
                            add textbox 
                            Serach button 
                            Add API to fetch the data by json data */}
                            {showFilter &&
                                <Col sm="auto" className="d-flex flex-nowrap">                                    
                                    <Form.Select className="mx-2 search-input" onChange={(e) => setSelectedColumn(e.target.value)} >
                                        <option value="">Select Column</option>
                                        {selectedTableColumn.map((column) => {                                        
                                            return <option key={column} value={column} >{formatColumnNames(column)}</option>
                                        })}
                                    </Form.Select>                                            
                                    <Form.Select className="mx-2 search-input" onChange={changeOperator} >
                                        <option value="">Select Column</option>
                                        {operator.map((op) => {                                        
                                            return <option key={op} value={op} >{op}</option>
                                        })}
                                    </Form.Select>                                
                                    <input className="mx-2 form-input form-control search-input"
                                        type="text"
                                        id="searchText" 
                                        placeholder= {serachPlaceHolder}
                                        ref={searchInputRef} 
                                    />                                    
                                    <button className="mx-2 default-btn-color mb-1 mx-1 py-1 btn btn-primary" onClick={handleClick}> Search </button>
                                </Col>
                            }

                        </Row>
                        <br></br>
                        <Row>
                            <ListView
                                exportTitle={"Audits"}
                                columnNames={columns}
                                rowData={audits}
                                rowClick={onClick}
                            />
                        </Row>
                    </Card.Body>
                </Card>
            </Row>
            <Modalpopup
                size={'lg'}
                show={showDetailsModal}
                handleClose={() => setShowDetailsModal(false)}
                actionTitle={detailsTitle}
            >
                <Row>
                    <div className="custom-table-view">
                        <table className="table custom-table" border={0}>
                            <tr>
                                <th>Field</th>
                                <th>Previous Value</th>
                                <th>New Value</th>
                            </tr>
                            {Object.keys(json).map((entry) =>
                                <tr>
                                    <td><span>{entry.replace(/_/g, " ")}</span></td>
                                    <td title={JSON.stringify(json[entry][0])}>
                                        {JSON.stringify(json[entry][0]) ? JSON.stringify(json[entry][0]).replace(/['"]+/g, '') : (<></>)}
                                    </td>
                                    <td title={JSON.stringify(json[entry][1])}>
                                        {JSON.stringify(json[entry][1]) ? JSON.stringify(json[entry][1]).replace(/['"]+/g, '') : (<></>)}
                                    </td>
                                </tr>
                            )}
                        </table>
                    </div>
                </Row>
            </Modalpopup>
        </>
    );
}
function createDifferencesObj(obj1, obj2) {
    let differences = {}
    let set = new Set()
    for (let key in obj1) {
        set.add(key)
    }
    for (let key in obj2) {
        set.add(key)
    }
    for (let key of set) {
        if (obj1?.[key] != obj2?.[key]) {
            if (key == "created_on" || key == "updated_on") {
                let objVal1 = "";
                let objVal2 = "";
                if (obj1?.[key] != undefined) {
                    objVal1 = new Date(obj1?.[key]).toLocaleString();
                }
                if (obj2?.[key] != undefined) {
                    objVal2 = new Date(obj2?.[key]).toLocaleString();
                }
                differences[key] = [objVal1, objVal2]
            } else if (key == "start_date" || key == "end_date") {
                let startObjVal = "";
                let endObjVal = "";
                if (obj1?.[key] != undefined) {
                    startObjVal = new Date(obj1?.[key]).toLocaleDateString();
                }
                if (obj2?.[key] != undefined) {
                    endObjVal = new Date(obj2?.[key]).toLocaleDateString();
                }
                differences[key] = [startObjVal, endObjVal]
            } else {
                differences[key] = [obj1?.[key], obj2?.[key]]
            }
        }
    }
    return differences
}