import { useEffect, useState } from "react";
import { Card, Col, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { Link } from "react-router-dom";
import Breadcrumbs from "../../../shared/components/Breadcrumbs";
import DefaultButton from "../../../shared/components/DefaultButton";
import { DELETE, CANCEL, BACKTOLIST, EDIT, EMPLOYEES, JOB_TITLE, EMPLOYEE_DEPARTMENT, EMPLOYEE_TYPE, HIRE_STATUS, MARKET, REGION, DIVISION, COMPANY, EMPLOYEE_STATUS, DELETE_CONFIRM, STORE } from "../../../shared/constants/Constants";
import Modalpopup from "../../../shared/components/Modalpopup";
import { loadEmployeeDeleteDataRequest, loadEmployeeDesignationTrackerByEmpIdDataRequest, loadEmployeeGetDataRequest, loadEmployeeTypeTrackerByEmpId, } from "../redux/Actions";
import { PrevNextButtons } from "../../../shared/components/PrevNextButtons";
import EmployeeDesignationTracker from "./EmployeeDesignationTracker";
import EmployeeTypeTracker from "./EmployeeTypeTracker";
import Permissions from "../../../shared/components/Permissions";
import FindModule from "../../../shared/components/FindModule";
import KeyTagModuleList from "../../../shared/components/tagging/KeyTagModuleList";
import Tagging from "../../../shared/components/tagging/Tagging";
import MyAdditionalInfo from "../../salesUsers/components/MyAdditionalInfo";
export default function EmployeeDetails() {

    let params = useParams();
    let navigate = useNavigate();
    const dispatch = useDispatch();
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [submittedDeleteRequest, setSubmittedDeleteRequest] = useState(false)
    const [shouldReload, setShouldReload] = useState(true);
    const [shouldReloadTrackers, setShouldReloadTrackers] = useState(true)

    let { loading, employee, designationTrackers, typeTrackers } = useSelector((store) => store.employee);

    useEffect(() => {
        if (shouldReload && !loading) {
            setShouldReload(false)
            dispatch(loadEmployeeGetDataRequest(params.id)) ///Load store data
            setShouldReloadTrackers(true)
        }
    }, [shouldReload, loading, params.id])

    useEffect(() => {
        if (employee?.employee_id && shouldReloadTrackers && !loading) {
            dispatch(loadEmployeeTypeTrackerByEmpId(employee?.employee_id))
            dispatch(loadEmployeeDesignationTrackerByEmpIdDataRequest(employee?.employee_id))
            setShouldReloadTrackers(false)
        }
    }, [employee?.employee_id, shouldReloadTrackers, loading])

    useEffect(() => {
        setShouldReload(true)
    }, [params.id])
    //Delete Functionality
    const handleDelete = () => {
        let deleteRequest = { id: [params.id] };
        dispatch(loadEmployeeDeleteDataRequest(deleteRequest));
        navigate('/employees')
    };


    let empInfo = JSON.parse(localStorage.getItem("employee"));

    let sessionFlag = false;
    if (employee?.employee_id === empInfo?.employee_id) {
        sessionFlag = true;
    }

    //Find Module
    let selectedModule = FindModule("Employees");
    let keytagModules = KeyTagModuleList(selectedModule?.id);     

    let trackers = [];
    let taggingTab = false;
    if (keytagModules.length !== 0) {
        taggingTab = true;
        if (sessionFlag) {
            trackers = [
                <EmployeeDesignationTracker employee_designation_tracker={designationTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <EmployeeTypeTracker employee_type_tracker={typeTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <Tagging resource_value={params.id} resource_id={selectedModule?.id} />,
                <MyAdditionalInfo />
            ]
        } else {
            trackers = [
                <EmployeeDesignationTracker employee_designation_tracker={designationTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <EmployeeTypeTracker employee_type_tracker={typeTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <Tagging resource_value={params.id} resource_id={selectedModule?.id} />
            ]

        }
    } else {
        taggingTab = false;
        if (sessionFlag) {
            trackers = [
                <EmployeeDesignationTracker employee_designation_tracker={designationTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <EmployeeTypeTracker employee_type_tracker={typeTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <MyAdditionalInfo />
            ]
        } else {
            trackers = [
                <EmployeeDesignationTracker employee_designation_tracker={designationTrackers} employee={employee} setShouldReload={setShouldReload} />,
                <EmployeeTypeTracker employee_type_tracker={typeTrackers} employee={employee} setShouldReload={setShouldReload} />,
            ]
        }
    }
    
    let [currentTracker, setCurrentTracker] = useState(1)

    let emp_no = JSON.parse(localStorage.getItem("emp_no"));
    let module_name = "Employees";
    let permission = Permissions(emp_no, module_name);

    return <div>
        <Row className="detailsPageHeader">
            <Col xl={4} xs={12}>
                <Breadcrumbs
                    children={[
                        { href: '/employees', label: EMPLOYEES },
                        { href: '/employees/', label: (employee?.first_name ?? "") + " " + (employee?.last_name ?? "") + ", " + (employee?.employee_id ?? '') },
                    ]}
                />
            </Col>
            <Col xl={8} xs={12}>
                <div className='float-end' >
                    <PrevNextButtons currentId={params.id} basePath={'/employees/'} name={EMPLOYEES} />
                    <Link to='/employees'>
                        <DefaultButton buttonClass='default-btn-color' buttonTitle={BACKTOLIST} />
                    </Link>
                    {permission.write_permission ? (<>
                        <DefaultButton buttonClass='btn btn-primary default-btn-color ms-1' buttonTitle={EDIT} actionBlock={() => {
                            navigate("/employees/edit/" + params.id);
                        }} />
                    </>) : (<></>)}
                    {permission.delete_permission ? (<>
                        <Button className="btn btn-danger ms-1" onClick={() => { setShowDeleteModal(true) }}>
                            <i className="fa fa-trash"></i>
                        </Button>
                    </>) : (<></>)}
                </div>
            </Col>
        </Row>
        <Row>
            <Card>
                <Card.Body>
                    <Row>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-4'>Emp. Number</dt>
                                <dd className='col-sm-8'>{employee?.employee_id}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>First Name</dt>
                                <dd className='col-sm-8'>{employee?.first_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Last Name</dt>
                                <dd className='col-sm-8'>{employee?.last_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Preferred Name</dt>
                                <dd className='col-sm-8'>{employee?.preferred_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>NTID</dt>
                                <dd className='col-sm-8'>{employee?.ntid}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>C Number</dt>
                                <dd className='col-sm-8'>{employee?.cnumber}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{JOB_TITLE}</dt>
                                <dd className='col-sm-8'>
                                    {/* <Link to={"/jobtitles/" + employee?.job_title_id}> */}
                                    {employee?.job_title_name}
                                    {/* </Link> */}
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{EMPLOYEE_DEPARTMENT}</dt>
                                <dd className='col-sm-8'>
                                    {employee?.department_name}
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Work Email</dt>
                                <dd className='col-sm-8'>{employee?.email_work}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Personal Email</dt>
                                <dd className='col-sm-8'>{employee?.email_personal}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Hire Date</dt>
                                <dd className='col-sm-8'>{employee?.hire_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Termination Date</dt>
                                <dd className='col-sm-8'>{employee?.term_date === '12-31-2099' ? '' : employee?.term_date}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Termination Reason</dt>
                                <dd className='col-sm-8'>{employee?.term_reason}</dd>
                            </dl>
                        </Col>
                        <Col>
                            <dl className='row'>
                                <dt className='col-sm-4'>{EMPLOYEE_TYPE}</dt>
                                <dd className='col-sm-8'>{employee?.full_part_time_status}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{HIRE_STATUS}</dt>
                                <dd className='col-sm-8'>{employee?.hire_status_name}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{STORE}</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/stores/" + employee?.store_id}>
                                        {employee?.store_sap_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{MARKET}</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/district/" + employee?.market_id}>
                                        {employee?.market_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{REGION}</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/region/" + employee?.region_id}>
                                        {employee?.region_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{DIVISION}</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/division/" + employee?.division_id}>
                                        {employee?.division_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{COMPANY}</dt>
                                <dd className='col-sm-8'>
                                    <Link to={"/company/" + employee?.company_id}>
                                        {employee?.company_name}
                                    </Link>
                                </dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Work Phone</dt>
                                <dd className='col-sm-8'>{employee?.work_phone}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Personal Phone</dt>
                                <dd className='col-sm-8'>{employee?.personal_phone}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Home Address</dt>
                                <dd className='col-sm-8'>{employee?.home_address}</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Supervisor</dt>
                                <dd className='col-sm-8'>{(employee?.supervisor_first_name ?? "") + " " + (employee?.supervisor_last_name ?? "") + "," +  (employee?.supervisor_employee_id ?? "")   }</dd>
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>Commisionable</dt>
                                <dd className='col-sm-8'>
                                    {employee?.is_commisionable ? (<i className="covering_store_checked"></i>) : (<i className="greyOutIcon"></i>)}
                                </dd>
                                <span></span>
                                
                            </dl>
                            <dl className='row'>
                                <dt className='col-sm-4'>{EMPLOYEE_STATUS}</dt>
                                <dd className='col-sm-8'>{employee?.emp_status}</dd>
                            </dl>
                        </Col >
                    </Row >
                </Card.Body >
            </Card >
        </Row >
        <Row style={{ marginTop: '5px' }}>
            <Col xs={6}>
                <h6 className='mt-4 mb-0 text-uppercase'>Employee Trackers</h6>
                <hr></hr>
            </Col>
        </Row>
        <Row>
            <Col>
            {sessionFlag ? (<>
                {taggingTab ? (
                    <>
                        <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Designation Tracker</button>
                        <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(2) }}>Employee Type Hire Status Tracker</button>
                        <button className={currentTracker === 3 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(3) }}>Additional Attribute</button>
                        <button className={currentTracker === 4 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(4) }}>Additional Info</button>
                    </>
                ) : (
                    <>
                        <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Designation Tracker</button>
                        <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(2) }}>Employee Type Hire Status Tracker</button>
                        <button className={currentTracker === 3 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(3) }}>Additional Info</button>
                    </>
                )} 
            </>) : (<>
                {taggingTab ? (
                    <>
                        <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Designation Tracker</button>
                        <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(2) }}>Employee Type Hire Status Tracker</button>
                        <button className={currentTracker === 3 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(3) }}>Additional Attribute</button>
                    </>
                ) : (
                    <>
                        <button className={currentTracker === 1 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(1) }}>Designation Tracker</button>
                        <button className={currentTracker === 2 ? "top-tab selected" : "top-tab"} onClick={() => { setCurrentTracker(2) }}>Employee Type Hire Status Tracker</button>
                    </>
                )}  
            </>)}             
            </Col>
            <Card >
                <Card.Body >
                    {trackers[currentTracker - 1]}
                </Card.Body>
            </Card>
        </Row>
        <Modalpopup
            show={showDeleteModal}
            handleClose={() => { setShowDeleteModal(false) }}
            actionTitle={"Delete Employee"}
        >
            <p>{DELETE_CONFIRM}</p>
            <div className="col-md-12">
                <DefaultButton
                    buttonClass={"btn btn-secondary me-2"}
                    buttonTitle={CANCEL}
                    actionBlock={() => { setShowDeleteModal(false) }}
                />
                <DefaultButton
                    buttonClass={"btn btn-danger"}
                    buttonTitle={DELETE}
                    actionBlock={handleDelete}
                />
            </div>
        </Modalpopup>
    </div >
}