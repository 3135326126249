const DENSITY_DATA = {
  DENSITY_DATA_LOAD: "DENSITY_DATA_LOAD",
  DENSITY_DATA_SUCCESS: "DENSITY_DATA_SUCCESS",
  DENSITY_DATA_FAIL: "DENSITY_DATA_FAIL",
};

const DENSITY_GET_API = "/store/density";

const DENSITYCLASS_DATA = {
  DENSITYCLASS_DATA_LOAD: "DENSITYCLASS_DATA_LOAD",
  DENSITYCLASS_DATA_SUCCESS: "DENSITYCLASS_DATA_SUCCESS",
  DENSITYCLASS_DATA_FAIL: "DENSITYCLASS_DATA_FAIL",
};

const DENSITYCLASS_GET_API = "/store/densityClass";

//Get
const DENSITY_GET_DATA = {
  DENSITY_GET_DATA_LOAD: "DENSITY_GET_DATA_LOAD",
  DENSITY_GET_DATA_SUCCESS: "DENSITY_GET_DATA_SUCCESS",
  DENSITY_GET_DATA_FAIL: "DENSITY_GET_DATA_FAIL",
};

const DENSITY_GET_DETAIL_API = "/store/density/";

// Create
const DENSITY_ADD_DATA = {
  DENSITY_ADD_DATA_LOAD: "DENSITY_ADD_DATA_LOAD",
  DENSITY_ADD_DATA_SUCCESS: "DENSITY_ADD_DATA_SUCCESS",
  DENSITY_ADD_DATA_FAIL: "DENSITY_ADD_DATA_FAIL",
};

const DENSITY_POST_API = "/store/density";

//Update
const DENSITY_UPDATE_DATA = {
  DENSITY_UPDATE_DATA_LOAD: "DENSITY_UPDATE_DATA_LOAD",
  DENSITY_UPDATE_DATA_SUCCESS: "DENSITY_UPDATE_DATA_SUCCESS",
  DENSITY_UPDATE_DATA_FAIL: "DENSITY_UPDATE_DATA_FAIL",
};

const DENSITY_PUT_API = "/store/density";

//Delete
const DENSITY_DELETE_DATA = {
  DENSITY_DELETE_DATA_LOAD: "DENSITY_DELETE_DATA_LOAD",
  DENSITY_DELETE_DATA_SUCCESS: "DENSITY_DELETE_DATA_SUCCESS",
  DENSITY_DELETE_DATA_FAIL: "DENSITY_DELETE_DATA_FAIL",
};

const DENSITY_DELETE_API = "/store/density";

export {
  DENSITY_DATA,
  DENSITY_GET_API,
  DENSITYCLASS_DATA,
  DENSITYCLASS_GET_API,
  DENSITY_GET_DATA,
  DENSITY_GET_DETAIL_API,
  DENSITY_ADD_DATA,
  DENSITY_POST_API,
  DENSITY_UPDATE_DATA,
  DENSITY_PUT_API,
  DENSITY_DELETE_DATA,
  DENSITY_DELETE_API,
};
