import React, { useEffect, useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { ErrorMessage, Field, Formik, useFormik, Form } from "formik";
import FormikDatePickerField from "../../../shared/components/FormikDatePickerField"
import DefaultButton from "../../../shared/components/DefaultButton";
import FormikSelectField from "../../../shared/components/FormikSelectField";
import { CANCEL, DIVISION, JOB_TITLE, MARKET, REGION, SAVE, STARTAFTEREND, STARTNOTFUTURE, START_DATE, END_DATE, STORE } from "../../../shared/constants/Constants";
import FormTextField from "../../../shared/components/FormTextField";
import { loadJobTitlesDataRequest } from "../../jobtitles/redux/Actions";
import { loadStoreDataRequest } from "../../stores/redux/Actions";
import { loadMarketDataRequest } from "../../market/redux/Actions";
import { loadRegionDataRequest } from "../../region/redux/Actions";
import { loadDivisionDataRequest } from "../../division/redux/Actions"
import { loadLeaveTypeDataRequest } from "../../employee_leave_type/redux/Actions";
import { Col } from "react-bootstrap";
import FormikCheckboxField from "../../../shared/components/FormikCheckboxField";
import FormatDate from "../../../shared/components/FormatDate";

const EmployeeDesignationForm = ({ tracker, submit, cancel, employee, allTrackers }) => {

    //Check the last but one tracker record for edit
    // let [showLastTracker, setShowLastTracker] = useState(false);
    // useEffect(() => {
    //     if(lastTracker){
    //         setShowLastTracker(lastTracker);
    //     }
    // },[lastTracker, setShowLastTracker]);

    const dispatch = useDispatch()
    const validate = (values) => {
        const errors = {};
        if (!values.job_title_id) {
            errors.job_title_id = "Required"
        }
        if (!values.start_date) {
            errors.start_date = "Required"
        }
        if (!values.end_date) {
            errors.end_date = "Required"
        }
        if (new Date(values.start_date) > new Date(values.end_date)) {
            errors.end_date = STARTAFTEREND
        }
        if (tracker && !values.covering_store && !values.covering_market && !values.covering_region) {
            for (let t of allTrackers) {
                if (t.id !== tracker.id) {
                    let testStartDate = new Date(values.start_date);
                    let testEndDate = new Date(values.end_date);
                    let startDate = new Date(t.start_date);
                    let endDate = new Date(t.end_date);
                    if (testStartDate >= startDate && testStartDate <= endDate) {
                        errors.start_date = "Date range is overlapping. Please correct the date range";
                    }
                    if (testEndDate >= startDate && testEndDate <= endDate) {
                        errors.end_date = "Date range is overlapping. Please correct the date range";
                    }
                }
            }
        }
        if (showStore) {
            if (!values.store_id || values.store_id == '') {
                errors.store_id = 'Required';
            }
        }
        if (showDistrict) {
            if (!values.market_id || values.market_id == '') {
                errors.market_id = 'Required';
            }
        }
        if (showRegion) {
            if (!values.region_id || values.region_id == '') {
                errors.region_id = 'Required';
            }
        }
        if (showDivision) {
            if (!values.division_id || values.division_id == '') {
                errors.division_id = 'Required';
            }
        }
        return errors;
    };
    const { jobtitles } = useSelector(store => store.jobtitle)
    const { stores } = useSelector(store => store.store);
    const { markets } = useSelector(store => store.market);
    const { regions } = useSelector(store => store.region);
    const { divisions } = useSelector(store => store.division);
    const { leaveTypes } = useSelector(store => store.leaveType)


    let [jobTitleId, setJobTitleId] = useState(null)

    useEffect(() => {
        if (jobtitles.length === 0) {
            dispatch(loadJobTitlesDataRequest());
        }
        if (stores.length === 0) {
            dispatch(loadStoreDataRequest());
        }
        if (markets.length === 0) {
            dispatch(loadMarketDataRequest())
        }
        if (regions.length === 0) {
            dispatch(loadRegionDataRequest());
        }
        if (divisions.length === 0) {
            dispatch(loadDivisionDataRequest());
        }
        if (leaveTypes.length === 0) {
            dispatch(loadLeaveTypeDataRequest());
        }
    }, [dispatch])


    const formik = useFormik({})
    useEffect(() => {
        if (tracker) {
            formik.current.setFieldValue('id', tracker?.id);
            // formik.current.setFieldValue('emp_number', tracker?.emp_number);
            formik.current.setFieldValue('employee_id', tracker?.employee_id);
            formik.current.setFieldValue('job_title_id', tracker?.job_title_id);
            setJobTitleId(tracker?.job_title_id);
            formik.current.setFieldValue('is_commisionable', tracker?.is_commisionable);
            formik.current.setFieldValue('covering_store', tracker?.covering_store);
            formik.current.setFieldValue('covering_market', tracker?.covering_market);
            formik.current.setFieldValue('covering_region', tracker?.covering_region);
            formik.current.setFieldValue('store_id', tracker?.store_id);
            formik.current.setFieldValue('market_id', tracker?.market_id);
            formik.current.setFieldValue('region_id', tracker?.region_id);
            formik.current.setFieldValue('division_id', tracker?.division_id);
            formik.current.setFieldValue('leave_type', tracker?.leave_type);
            if (tracker && tracker.start_date && tracker.end_date) {
                if (tracker?.start_date.includes("-") && tracker?.end_date.includes("-")) {
                    formik.current.setFieldValue('start_date', FormatDate(tracker.start_date));
                    formik.current.setFieldValue('end_date', FormatDate(tracker.end_date));
                }
            }
        }
    }, [tracker])
    useEffect(() => {
        if (employee) {
            formik.current.setFieldValue('employee_id', employee.employee_id)
        }
    }, [employee, formik])

    let [showStore, setShowStore] = useState(false);
    let [showCovering, setShowCovering] = useState(false);
    let [showCommisionable, setShowCommisionable] = useState(false)
    let [showDistrict, setShowDistrict] = useState(false);
    let [showDistrictCovering, setShowDistrictCovering] = useState(false);
    let [showRegion, setShowRegion] = useState(false);
    let [showRegionCovering, setShowRegionCovering] = useState(false);
    let [showDivision, setShowDivision] = useState(false);


    const [selectedJob, setSelectedJob] = useState();
    useEffect(() => {
        setSelectedJob(jobtitles.find(jobtitle => jobtitle.id === Number(jobTitleId)));
    }, [jobTitleId])

    useEffect(() => {
        if (tracker) {
            setVisibleFilters(tracker?.job_title_id, tracker.leave_type !== null)
        }
    }, [tracker, jobtitles])
    const setVisibleFilters = (job_title_id, on_leave) => {
        if (!jobtitles || jobtitles.length == 0 || !job_title_id) {
            console.log("Titles not loaded or not set")
            return
        }
        let shouldShowStore = false;
        let shouldShowCovering = false;
        let shouldShowCommisionable = false;
        let shouldShowDistrict = false;
        let shouldShowDistrictCovering = false;
        let shouldShowRegion = false;
        let shouldShowRegionCovering = false;
        let shouldShowDivision = false;

        if (!on_leave) {
            let jobTitle = jobtitles.find(jobtitle => jobtitle.id === Number(job_title_id))
            if (!jobTitle) {
                console.log("Could not find job title in list")
                console.log(jobtitles, job_title_id)
                return
            }
            if (jobTitle.multiple_location_type !== null) {
                jobTitle.level_name = jobTitle.multiple_location_type;
            }
            switch (jobTitle.level_name) {
                case 'store':
                    formik.current.setFieldValue('is_commisionable', false);
                    formik.current.setFieldValue('covering_store', false);
                    formik.current.setFieldValue('covering_market', false);
                    formik.current.setFieldValue('covering_region', false);
                    shouldShowStore = true;
                    if (jobTitle.default_value) {
                        formik.current.setFieldValue('store_id', jobTitle.default_value)  
                    }
                    break;
                case 'corporate':
                    formik.current.setFieldValue('is_commisionable', false);
                    formik.current.setFieldValue('covering_store', false);
                    formik.current.setFieldValue('covering_market', false);
                    formik.current.setFieldValue('covering_region', false);
                    formik.current.setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                    break;
                case 'store_covering':
                    formik.current.setFieldValue('covering_market', false);
                    formik.current.setFieldValue('covering_region', false);
                    shouldShowStore = true;
                    if (jobTitle.job_title == 'Retail Store Manager' || jobTitle.job_title == "Market Learning Center Manager" || jobTitle.job_title == "Interim Retail Store Manager") {
                        shouldShowCovering = true;
                        shouldShowCommisionable = true;
                    }
                    if (jobTitle.default_value) {
                        formik.current.setFieldValue('store_id', jobTitle.default_value)
                    }
                    break;
                case 'district':
                    //formik.current.setFieldValue('is_commisionable', false);
                    formik.current.setFieldValue('covering_store', false);
                    formik.current.setFieldValue('covering_region', false);
                    formik.current.setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                    shouldShowDistrict = true;
                    if (jobTitle.default_value) {
                        formik.current.setFieldValue('market_id', jobTitle.default_value)
                    }
                    if (jobTitle.job_title == 'District Sales and Ops Manager') {
                        shouldShowDistrictCovering = true;
                        shouldShowCommisionable = true;
                    }
                    break;
                case 'region':
                    formik.current.setFieldValue('is_commisionable', false);
                    formik.current.setFieldValue('covering_store', false);
                    formik.current.setFieldValue('covering_market', false);
                    formik.current.setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                    shouldShowRegion = true;
                    if (jobTitle.default_value) {
                        formik.current.setFieldValue('region_id', jobTitle.default_value)
                    }
                    if (jobTitle.job_title == 'Director of Sales & Operations') {
                        shouldShowRegionCovering = true;
                    }
                    break;
                case 'division':
                    formik.current.setFieldValue('is_commisionable', false);
                    formik.current.setFieldValue('covering_store', false);
                    formik.current.setFieldValue('covering_market', false);
                    formik.current.setFieldValue('covering_region', false);
                    formik.current.setFieldValue('store_id', stores.find((store) => store.sap === '1')?.id);
                    shouldShowRegion = true;
                    shouldShowDivision = true;
                    if (jobTitle.default_value) {
                        formik.current.setFieldValue('division_id', jobTitle.default_value)
                    }
                    break;
            }
        }
        if (shouldShowStore != showStore) {
            setShowStore(shouldShowStore)
        }
        if (shouldShowCovering != showCovering) {
            setShowCovering(shouldShowCovering)
        }
        if (shouldShowCommisionable != showCommisionable) {
            setShowCommisionable(shouldShowCommisionable)
        }
        if (shouldShowRegion != showRegion) {
            setShowRegion(shouldShowRegion)
        }
        if (shouldShowRegionCovering != showRegionCovering) {
            setShowRegionCovering(shouldShowRegion);
        }
        if (shouldShowDivision != showDivision) {
            setShowDivision(shouldShowDivision)
        }
        if (shouldShowDistrict != showDistrict) {
            setShowDistrict(shouldShowDistrict)
        }
        if (shouldShowDistrictCovering != showDistrictCovering) {
            setShowDistrictCovering(shouldShowDistrictCovering)
        }

    }
    useEffect(() => {
        setVisibleFilters();
    }, [jobTitleId, jobtitles, formik?.values?.leave_type]);

    const coveringChange = (value) =>{
        if (value){
            let today = new Date()
            today.setMonth(today.getMonth()+1)
            formik.current.setFieldValue('end_date',today.toLocaleDateString())
        }else{
            formik.current.setFieldValue('end_date','12/31/2099')
        }
    }
    return <Formik
        innerRef={formik}
        initialValues={{
            id: null,
            employee_id: employee?.employee_id,
            job_title_id: null,
            is_commisionable: false,
            covering_store: false,
            covering_market: false,
            covering_region: false,
            store_id: null,
            market_id: null,
            region_id: null,
            division_id: null,
            leave_type: false,
            start_date: null,
            end_date: '12/31/2099',
        }}
        validate={validate}
        onSubmit={submit}
    >
        {({ values, setFieldTouched, setFieldValue }) => (
            <Form className="row g-4">
                {/* <FormTextField name="employee_id" label='Employee Number' disabled /> */}
                <div className="col-md-6">
                    <label htmlFor="employee_id" className="form-label">
                        Employee Number
                    </label>
                    <Field name="emp_name_num" diabled={true} className="form-control" type='text' readOnly={true} defaultValue={(employee?.first_name ?? "") + " " + (employee?.last_name ?? "") + ", " + (employee?.employee_id ?? '')} />
                    <Field type="hidden" name="employee_id" />
                </div>
                <FormikSelectField
                    name='job_title_id'
                    label={JOB_TITLE}
                    value={values.job_title_id}
                    onChange={(name, value) => {
                        setFieldValue(name, value).then(() => {
                            setTimeout(() => { setJobTitleId(value) }, 50);
                        })
                        setVisibleFilters(value, values.leave_type);
                    }}
                    placeholder={JOB_TITLE}
                    required
                >
                    {jobtitles.map(jobTitle =>
                        <option value={jobTitle.id}>
                            {jobTitle.job_title}
                        </option>
                    )}
                </FormikSelectField>
                
                <FormikCheckboxField name="is_commisionable" label="Commisionable" hidden={!showCommisionable} value={values.is_commisionable} setFieldValue={setFieldValue} />

                <FormikCheckboxField name="covering_store" label="Covering Store" hidden={!showCovering} value={values.covering_store} setFieldValue={setFieldValue} onChange={coveringChange}/>
                <FormikCheckboxField name="covering_market" label="Covering District" hidden={!showDistrictCovering} value={values.covering_market} setFieldValue={setFieldValue} onChange={coveringChange}/>
                <FormikCheckboxField name="covering_region" label="Covering Region" hidden={!showRegionCovering} value={values.covering_region} setFieldValue={setFieldValue} onChange={coveringChange}/>
                <FormikSelectField
                    name='store_id'
                    label={STORE}
                    hidden={!showStore}
                    value={values.store_id}
                    onChange={setFieldValue}
                    placeholder={STORE}
                    isMulti={selectedJob?.is_multiple}
                    required
                >
                    {stores.filter(store => (store.active === 0 || store.active === 2) && (store.sap !== 'B000' || values.covering_store !== true)).map(store =>
                        <option value={store.id}>
                            {store.store_name + ", " + (store.sap ?? '')}
                        </option>
                    )}
                </FormikSelectField>
                <FormikSelectField
                    name='market_id'
                    label={MARKET}
                    hidden={!showDistrict}
                    value={values.market_id}
                    onChange={setFieldValue}
                    placeholder={MARKET}
                    isMulti={selectedJob?.is_multiple}
                    required
                >
                    {markets.filter(market => (market.active === 0 || market.active === 2)).map(market =>
                        <option value={market.id}>
                            {market.market_name}
                        </option>
                    )}
                </FormikSelectField>
                <FormikSelectField
                    hidden={!showRegion}
                    name='region_id'
                    label={REGION}
                    value={values.region_id}
                    onChange={setFieldValue}
                    placeholder={REGION}
                    isMulti={selectedJob?.is_multiple}
                >
                    {regions.filter(region => (region.active === 0 || region.active === 2)).map((region) =>
                        <option value={region.id}>{region.region_name}</option>
                    )}
                </FormikSelectField>
                <FormikSelectField
                    name='division_id'
                    label={DIVISION}
                    hidden={!showDivision}
                    value={values.division_id}
                    onChange={setFieldValue}
                    placeholder={DIVISION}
                >
                    {divisions.map(division => <option value={division.id}>{division.division_name}</option>)}
                </FormikSelectField>
                <FormikCheckboxField name='leave_type' label='On Leave' setFieldValue={setFieldValue} onChange={(value) => setVisibleFilters(values.job_title_id, value)} value={values.leave_type} />
                <div className="col-md-6 datepicker">
                    <label htmlFor={'start_date'} className="form-label">
                        {START_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField
                        name="start_date"
                        value={values.start_date}
                        onChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        placeholder={START_DATE}
                    />
                    <span className="error-text">
                        <ErrorMessage name={'start_date'} />
                    </span>
                </div>
                <div className="col-md-6 datepicker">
                    <label htmlFor={'end_date'} className="form-label">
                        {END_DATE} <span className="error-text">*</span>
                    </label>
                    <FormikDatePickerField
                        name="end_date"
                        value={values.end_date}
                        onChange={setFieldValue}
                        handleBlur={setFieldTouched}
                        placeholder={END_DATE}
                    />
                    <span className="error-text">
                        <ErrorMessage name={'end_date'} />
                    </span>
                </div>
                <Col md={12}>
                    <DefaultButton
                        buttonClass={"btn btn-secondary me-2"}
                        buttonTitle={CANCEL}
                        actionBlock={cancel}
                    />
                    <DefaultButton
                        buttonClass={"btn btn-secondary default-btn-color"}
                        buttonTitle={SAVE}
                        buttonType={"submit"}
                    />
                </Col>
            </Form>
        )}
    </Formik>
}

export default EmployeeDesignationForm