import { takeLatest, put, call } from "redux-saga/effects";
import {
  MYPLAN_GET_DATA,
  MYPLAN_GET_DETAIL_API,
  MYPLAN_ADD_DATA,
  MYPLAN_POST_API,
  MYPLAN_UPDATE_DATA,
  MYPLAN_PUT_API,
  MYPLAN_DELETE_DATA,
  MYPLAN_DELETE_API,
  MYPLANNOTE_ADD_DATA,
  MYPLANNOTE_POST_API,

  MYPLANMGRNOTE_ADD_DATA,
  MYPLANMGRNOTE_POST_API,

  MYPLANNOTE_DELETE_DATA,
  MYPLANNOTE_DELETE_API,
  TEAMPLAN_GET_DATA  ,
  TEAMPLAN_GET_DETAIL_API,
  PLAN_BY_LOC_LEVEL_GET_DATA  ,
  PLAN_BY_LOC_LEVEL_GET_DETAIL_API,
  MYLOC_GET_DATA  ,
  MYLOC_GET_DETAIL_API,
  MYPLAN_EMP_LOC_PER_GET_DATA,
  MYPLAN_EMP_LOC_PER_GET_DETAIL_API,
  PLAN_BY_EMP_GET_DATA,
  PLAN_BY_EMP_GET_DETAIL_API,
  PRIORITY_BY_ID_GET_DATA,
  PRIORITY_BY_ID_GET_DETAIL_API,
  INSERT_T0_NEWQUARTER_ADD_DATA,
  INSERT_T0_NEWQUARTER_POST_API

} from "./Constants";
import {
  setMyPlanDataSuccess,
  setMyPlanDataFail,
  setMyPlanAddDataSuccess,
  setMyPlanAddDataFail,
  setMyPlanGetDataSuccess,
  setMyPlanGetDataFail,
  setMyPlanUpdateDataSuccess,
  setMyPlanUpdateDataFail,
  setMyPlanDeleteDataSuccess,
  setMyPlanDeleteDataFail,
  setMyPlanNoteAddDataSuccess,
  setMyPlanNoteAddDataFail,

  setMyPlanMgrNoteAddDataSuccess,
  setMyPlanMgrNoteAddDataFail,

  setMyPlanNoteDeleteDataSuccess,
  setMyPlanNoteDeleteDataFail,
  setTeamPlanGetDataSuccess,
  setTeamPlanGetDataFail,
  setMyLocGetDataSuccess,
  setMyLocGetDataFail,
  setPlanByEmpLocPerGetDataSuccess,
  setPlanByEmpLocPerGetDataFail,
  setAllPlanByEmpGetDataSuccess,
  seAllPlanByEmpGetDataFail ,
  setPlanByLocLevelGetDataSuccess,
  setPlanByLocLevelGetDataFail,
  setMyPrioritybyIdDataSuccess,
  setMyPrioritybyIdDataFail,
  setCopyPlantoNewQuarterAddDataSuccess,
  setCopyPlantoNewQuarterAddDataFail

} from "./Actions";
import {
  getRequest,
  postRequest,
  getDetailRequest,
  putRequest,
  deleteRequest,
} from "../../../shared/components/Service";
import { addErrorAlert, addSuccessAlert } from "../../alert/Actions";

// function* getMyPlansDataService() {
//   try {
//     const acquisitionResponse = yield call(getRequest, ACQUISITION_GET_API);
//     const status = acquisitionResponse.status;
//     if (status === 200) {
//       const data = acquisitionResponse.data;
//       yield put(setMyPlanDataSuccess(data));
//     } else {
//       yield put(setMyPlanDataFail(acquisitionResponse.response.data));
//     }
//   } catch (error) {
//     yield put(setMyPlanDataFail(error));
//   }
// }

function* postCopyPlantoNewQuarterDataService({ request }) {
  try {
    console.log("request in saga", request)
    const CopyPlantoNewQuarterResponse = yield call(
      postRequest,
      INSERT_T0_NEWQUARTER_POST_API,
      request
    );
    const status = CopyPlantoNewQuarterResponse.status;
    if (status === 200) {
      const newData = CopyPlantoNewQuarterResponse.data;
      yield put(addSuccessAlert('MyPlan Created'))
      yield put(setCopyPlantoNewQuarterAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(CopyPlantoNewQuarterResponse.response.data.msg))
      yield put(setCopyPlantoNewQuarterAddDataFail(CopyPlantoNewQuarterResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding myPlan"))
    yield put(setCopyPlantoNewQuarterAddDataFail(error));
  }
}

function* postMyPlanDataService({ request }) {
  try {
    console.log("request in saga", request)
    const myPlanDataResponse = yield call(
      postRequest,
      MYPLAN_POST_API,
      request
    );
    const status = myPlanDataResponse.status;
    if (status === 200) {
      const newData = myPlanDataResponse.data;
      yield put(addSuccessAlert('MyPlan Created'))
      yield put(setMyPlanAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(myPlanDataResponse.response.data.msg))
      yield put(setMyPlanAddDataFail(myPlanDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding myPlan"))
    yield put(setMyPlanAddDataFail(error));
  }
}


function* postMyPlanNoteDataService({ request }) {
  try {
    console.log("request in saga", request)
    const myPlanNoteDataResponse = yield call(
      postRequest,
      MYPLANNOTE_POST_API,
      request
    );
    const status = myPlanNoteDataResponse.status;
    if (status === 200) {
      const newData = myPlanNoteDataResponse.data;
      yield put(addSuccessAlert('MyPlan Created'))
      yield put(setMyPlanNoteAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(myPlanNoteDataResponse.response.data.msg))
      yield put(setMyPlanNoteAddDataFail(myPlanNoteDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding myPlan"))
    yield put(setMyPlanNoteAddDataFail(error));
  }
}


function* postMyPlanMgrNoteDataService({ request }) {
  try {
    console.log("request in saga", request)
    const myPlanMgrNoteDataResponse = yield call(
      postRequest,
      MYPLANMGRNOTE_POST_API,
      request
    );
    const status = myPlanMgrNoteDataResponse.status;
    if (status === 200) {
      const newData = myPlanMgrNoteDataResponse.data;
      yield put(addSuccessAlert('Manager Notes Added'))
      yield put(setMyPlanMgrNoteAddDataSuccess(newData));
    } else {
      yield put(addErrorAlert(myPlanMgrNoteDataResponse.response.data.msg))
      yield put(setMyPlanMgrNoteAddDataFail(myPlanMgrNoteDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding myPlan"))
    yield put(setMyPlanMgrNoteAddDataFail(error));
  }
}


function* deleteMyPlanNoteDataService({ request }) {
  try {
    console.log("request in saga", request)
    const myPlanDeleteNoteDataResponse = yield call(
      postRequest,
      MYPLANNOTE_DELETE_API,
      request
    );
    const status = myPlanDeleteNoteDataResponse.status;
    if (status === 200) {
      const newData = myPlanDeleteNoteDataResponse.data;
      yield put(addSuccessAlert('MyPlan Created'))
      yield put(setMyPlanNoteDeleteDataSuccess(newData));
    } else {
      yield put(addErrorAlert(myPlanDeleteNoteDataResponse.response.data.msg))
      yield put(setMyPlanNoteDeleteDataFail(myPlanDeleteNoteDataResponse.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error adding myPlan"))
    yield put(setMyPlanNoteDeleteDataFail(error));
  }
}

function* getMyPlanDataService({ request }) {
  try {
    const getMyPlanDataResponse = yield call(
      postRequest,
      MYPLAN_GET_DETAIL_API ,
      request
    );
    const status = getMyPlanDataResponse.status;
    if (status === 200) {
      const newData = getMyPlanDataResponse.data;
      yield put(setMyPlanGetDataSuccess(newData));
    } else {
      yield put(setMyPlanGetDataFail(getMyPlanDataResponse.response.data));
    }
  } catch (error) {
    yield put(setMyPlanGetDataFail(error));
  }
}


function* getMyLocDataService({ id }) {
  try {
    const getMyLocDataResponse = yield call(
      getDetailRequest,
      MYLOC_GET_DETAIL_API + id
    );
    const status = getMyLocDataResponse.status;
    if (status === 200) {
      const newData = getMyLocDataResponse.data;
      yield put(setMyLocGetDataSuccess(newData));
    } else {
      yield put(setMyLocGetDataFail(getMyLocDataResponse.response.data));
    }
  } catch (error) {
    yield put(setMyLocGetDataFail(error));
  }
}

function* getTeamPlanDataService({ request }) {
  try {
    const getTeamPlanDataResponse = yield call(
      postRequest,
      TEAMPLAN_GET_DETAIL_API , request
    );
    const status = getTeamPlanDataResponse.status;
    if (status === 200) {
      const newData = getTeamPlanDataResponse.data;
      yield put(setTeamPlanGetDataSuccess(newData));
    } else {
      yield put(setTeamPlanGetDataFail(getTeamPlanDataResponse.response.data));
    }
  } catch (error) {
    yield put(setTeamPlanGetDataFail(error));
  }
}


function* getPlanByLocLevelDataService({ request }) {
  try {
    const getPlanByLocLevelDataResponse = yield call(
      postRequest,
      PLAN_BY_LOC_LEVEL_GET_DETAIL_API , request
    );
    const status = getPlanByLocLevelDataResponse.status;
    if (status === 200) {
      const newData = getPlanByLocLevelDataResponse.data;
      yield put(setPlanByLocLevelGetDataSuccess(newData));
    } else {
      yield put(setPlanByLocLevelGetDataFail(getPlanByLocLevelDataResponse.response.data));
    }
  } catch (error) {
    yield put(setPlanByLocLevelGetDataFail(error));
  }
}


function* getPlanByEmpLocPerDataService({ request }) {
  try {
    const getPlanByEmpLocPerDataResponse = yield call(
      postRequest,
      MYPLAN_EMP_LOC_PER_GET_DETAIL_API , request
    );
    const status = getPlanByEmpLocPerDataResponse.status;
    if (status === 200) {
      const newData = getPlanByEmpLocPerDataResponse.data;
      yield put(setPlanByEmpLocPerGetDataSuccess(newData));
    } else {
      yield put(setPlanByEmpLocPerGetDataFail(getPlanByEmpLocPerDataResponse.response.data));
    }
  } catch (error) {
    yield put(setPlanByEmpLocPerGetDataFail(error));
  }
}



function* putMyPlanDataService({ request }) {
  try {
    const response = yield call(putRequest, MYPLAN_PUT_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("MyPlan updated"))
      yield put(setMyPlanUpdateDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setMyPlanUpdateDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error updating myPlan"))
    yield put(setMyPlanUpdateDataFail(error));
  }
}

function* deleteMyPlanDataService({ request }) {
  try {
    const response = yield call(deleteRequest, MYPLAN_DELETE_API, request);
    if (response.status === 200) {
      yield put(addSuccessAlert("MyPlan deleted"))
      yield put(setMyPlanDeleteDataSuccess(response.data));
    } else {
      yield put(addErrorAlert(response.response.data.msg))
      yield put(setMyPlanDeleteDataFail(response.response.data));
    }
  } catch (error) {
    yield put(addErrorAlert("Error deleting myPlan"))
    yield put(setMyPlanDeleteDataFail(error));
  }
}

//get all plans by employee id 
function* getAllPlanByEmpDataService({ id }) {
  try {
    const getAllPlanByEmpDataResponse = yield call(
      getDetailRequest,
      PLAN_BY_EMP_GET_DETAIL_API + id
    );
    const status = getAllPlanByEmpDataResponse.status;
    if (status === 200) {
      const newData = getAllPlanByEmpDataResponse.data;
      yield put(setAllPlanByEmpGetDataSuccess(newData));
    } else {
      yield put(seAllPlanByEmpGetDataFail(getAllPlanByEmpDataResponse.response.data));
    }
  } catch (error) {
    yield put(seAllPlanByEmpGetDataFail(error));
  }
}

// Get Priority by Id
function* getAllPriorityById({ request }) {
  try {
    const getAllPriorityByIdResponse = yield call(
      getDetailRequest,
      PRIORITY_BY_ID_GET_DETAIL_API, request
    );
    const status = getAllPriorityByIdResponse.status;
    if (status === 200) {
      const newData = getAllPriorityByIdResponse.data;
      yield put(setMyPrioritybyIdDataSuccess(newData));
    } else {
      yield put(setMyPrioritybyIdDataFail(getAllPriorityByIdResponse.response.data));
    }
  } catch (error) {
    yield put(setMyPrioritybyIdDataFail(error));
  }
}


export function* watchMyPlan() {
  // yield takeLatest(
  //   ACQUISITION_DATA.ACQUISITION_DATA_LOAD,
  //   getMyPlansDataService
  // );
  yield takeLatest(
    MYPLAN_ADD_DATA.MYPLAN_ADD_DATA_LOAD,
    postMyPlanDataService
  );
  yield takeLatest(
    MYPLANNOTE_ADD_DATA.MYPLANNOTE_ADD_DATA_LOAD,
    postMyPlanNoteDataService
  );

  yield takeLatest(
    MYPLANMGRNOTE_ADD_DATA.MYPLANMGRNOTE_ADD_DATA_LOAD,
    postMyPlanMgrNoteDataService
  );
  
  yield takeLatest(
    MYPLANNOTE_DELETE_DATA.MYPLANNOTE_DELETE_LOAD,
    deleteMyPlanNoteDataService
  );
  yield takeLatest(
    MYPLAN_GET_DATA.MYPLAN_GET_DATA_LOAD,
    getMyPlanDataService
  );
  yield takeLatest(
    MYPLAN_UPDATE_DATA.MYPLAN_UPDATE_DATA_LOAD,
    putMyPlanDataService
  );
  yield takeLatest(
    MYPLAN_DELETE_DATA.MYPLAN_DELETE_DATA_LOAD,
    deleteMyPlanDataService
  );
  yield takeLatest(
    MYLOC_GET_DATA.MYLOC_GET_DATA_LOAD,
    getMyLocDataService
  );  
  yield takeLatest(
    TEAMPLAN_GET_DATA.TEAMPLAN_GET_DATA_LOAD,
    getTeamPlanDataService
  );
  
  yield takeLatest(
    PLAN_BY_LOC_LEVEL_GET_DATA.PLAN_BY_LOC_LEVEL_GET_DATA_LOAD,
    getPlanByLocLevelDataService
  );

  yield takeLatest(
    MYPLAN_EMP_LOC_PER_GET_DATA.MYPLAN_EMP_LOC_PER_GET_DATA_LOAD,
    getPlanByEmpLocPerDataService
  );
  yield takeLatest(
    PLAN_BY_EMP_GET_DATA.PLAN_BY_EMP_GET_DATA_LOAD,
    getAllPlanByEmpDataService
  );
  yield takeLatest(
    PRIORITY_BY_ID_GET_DATA.PRIORITY_BY_ID_GET_DATA_LOAD,
    getAllPriorityById
  );
  yield takeLatest(
    INSERT_T0_NEWQUARTER_ADD_DATA.INSERT_T0_NEWQUARTER_ADD_DATA_LOAD,
    postCopyPlantoNewQuarterDataService
  );

}
