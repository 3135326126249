import {
  AUDIT_DATA, AUDIT_GET_TABLES,AUDIT_DATA_BY_SEARCH
} from "./Constants";

//For listing
const loadAuditDataRequest = (id) => ({
  type: AUDIT_DATA.AUDIT_DATA_LOAD,
  id,
});
const setAuditDataSuccess = (audits) => ({
  type: AUDIT_DATA.AUDIT_DATA_SUCCESS,
  audits,
});
const setAuditDataFail = (error) => ({
  type: AUDIT_DATA.AUDIT_DATA_FAIL,
  error,
});


//For JSON Search
const loadAuditDataBySearchRequest = (request) => ({
  type: AUDIT_DATA_BY_SEARCH.AUDIT_DATA_BY_SEARCH_LOAD,
  request,
});
const setAuditDataBySearchSuccess = (audits) => ({
  type: AUDIT_DATA_BY_SEARCH.AUDIT_DATA_BY_SEARCH_SUCCESS,
  audits,
});
const setAuditDataBySearchFail = (error) => ({
  type: AUDIT_DATA_BY_SEARCH.AUDIT_DATA_BY_SEARCH_FAIL,
  error,
});

const loadAuditTablesRequest = () => ({
  type: AUDIT_GET_TABLES.AUDIT_GET_TABLES_LOAD
})
const setAuditTablesSuccess = (tables) => ({
  type: AUDIT_GET_TABLES.AUDIT_GET_TABLES_SUCCESS,
  tables
})
const setAuditTablesFail = (error) => ({
  type: AUDIT_GET_TABLES.AUDIT_GET_TABLES_FAIL,
  error
})

export {
  loadAuditDataRequest,  setAuditDataSuccess,  setAuditDataFail,
  loadAuditDataBySearchRequest,  setAuditDataBySearchSuccess,  setAuditDataBySearchFail,
  loadAuditTablesRequest, setAuditTablesSuccess, setAuditTablesFail,
};
